var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5"},[(this.loading === true)?_c('v-skeleton-loader',{attrs:{"type":"heading","min-height":"57"}}):_vm._e(),_c('h3',{staticClass:"pt-3"},[_vm._v(" General Journal Entry - "+_vm._s(_vm.journal_entry_number)+" ")])],1)],1)],1),_c('v-col')],1),_c('v-row',[_c('v-col',{attrs:{"sm":"3","md":"3"}},[_c('h4',[_vm._v("Attachments")])])],1),(_vm.value.attachments.length > 0)?_c('div',_vm._l((_vm.value.attachments),function(item){return _c('v-row',{key:item.file_name},[_c('v-col',{attrs:{"sm":"3","md":"3"}},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":item.src,"target":"blank"}},[_vm._v(" "+_vm._s(item.file_name)+" ")])])],1)}),1):_c('v-row',[_c('v-col',{attrs:{"sm":"3","md":"3"}},[_vm._v(" None ")])],1),_c('v-row',{staticClass:"my-3"},[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',_vm._l(([
        { header: 'Added By' },
        { header: 'Entry Date' },
        { header: 'Reference #' },
        { header: 'Beneficiary' } ]),function(item){return _c('v-col',{key:item.header,attrs:{"sm":"3","md":"3"}},[_c('h4',[_vm._v(_vm._s(item.header))])])}),1),_c('v-row',_vm._l(([
        { name: _vm.journal_entry_created_by_name },
        { name: _vm.journal_entry_date },
        { name: _vm.reference_number } ]),function(item){return _c('v-col',{key:item.reference_number,attrs:{"sm":"3","md":"3"}},[_vm._v(" "+_vm._s(item.reference_number)+" ")])}),1),_c('v-row',{staticClass:"my-3"},[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',_vm._l(([{ header: 'Ledger Account' }, { header: 'Amount' }]),function(item){return _c('v-col',{key:item.header,attrs:{"sm":"3","md":"3"}},[_c('h4',[_vm._v(_vm._s(item.header))])])}),1),_vm._l((_vm.line_items),function(item){return _c('v-row',{key:item.ledger_account.account_name},[_c('v-col',{attrs:{"sm":"3","md":"3"}},[_vm._v(" "+_vm._s(item.ledger_account.account_name)+" ")]),_c('v-col',{attrs:{"sm":"3","md":"3"}},[_vm._v(" "+_vm._s(_vm.formatAsCurrency("R", item.entry_amount))+" ")])],1)}),_c('v-row',{staticClass:"my-3"},[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',_vm._l(([
        { header: 'Allocated To' },
        { header: 'Bank Account' },
        { header: 'Transaction Type' } ]),function(item){return _c('v-col',{key:item.header,attrs:{"sm":"3","md":"3"}},[_c('h4',[_vm._v(_vm._s(item.header))])])}),1),_c('v-row',_vm._l(([
        {
          name: _vm.value.transaction_number,
        },
        { name: _vm.value.bank_account_name },
        { name: _vm.value.transaction_type } ]),function(item){return _c('v-col',{key:item.name,attrs:{"sm":"3","md":"3"}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1),_c('v-row',{staticClass:"my-3"},[_c('v-col',[_c('v-divider')],1)],1),_c('v-card-actions',[_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-spacer'),_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Remove & Redo ")])]}}]),model:{value:(_vm.remove_and_redo_dialog),callback:function ($$v) {_vm.remove_and_redo_dialog=$$v},expression:"remove_and_redo_dialog"}},[_c('general-journal-entry-remove-dialog',{attrs:{"remove_and_redo_dialog":this.remove_and_redo_dialog,"value":this.value},on:{"changeRemoveDialog":function($event){return _vm.changeRemoveDialog($event)},"closeDialog":function($event){return _vm.closeDialog($event)}}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }