<template>
  <v-container fluid>
    <v-row>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5">
            <!--Heading-->
            <v-skeleton-loader
              v-if="this.loading === true"
              type="heading"
              min-height="57"
            >
            </v-skeleton-loader>
            <h3 class="pt-3">
              General Journal Entry - {{ journal_entry_number }}
            </h3>
            <!--End of heading-->
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-col> </v-col>
    </v-row>
    <!--Attachments-->
    <v-row>
      <v-col sm="3" md="3">
        <h4>Attachments</h4>
      </v-col>
    </v-row>
    <div v-if="value.attachments.length > 0">
      <v-row v-for="item in value.attachments" :key="item.file_name">
        <v-col sm="3" md="3">
          <a :href="item.src" style="text-decoration: none" target="blank">
            {{ item.file_name }}
          </a>
        </v-col>
      </v-row>
    </div>
    <v-row v-else>
      <v-col sm="3" md="3"> None </v-col>
    </v-row>
    <!--Divider-->
    <v-row class="my-3">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <!--Entry details-->
    <v-row>
      <v-col
        sm="3"
        md="3"
        v-for="item in [
          { header: 'Added By' },
          { header: 'Entry Date' },
          { header: 'Reference #' },
          { header: 'Beneficiary' },
        ]"
        :key="item.header"
      >
        <h4>{{ item.header }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        sm="3"
        md="3"
        v-for="item in [
          { name: journal_entry_created_by_name },
          { name: journal_entry_date },
          { name: reference_number },
          //{ name: supplier.supplier_name },
        ]"
        :key="item.reference_number"
      >
        {{ item.reference_number }}
      </v-col>
    </v-row>
    <!--Divider-->
    <v-row class="my-3">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <!--Ledger account details-->
    <v-row>
      <v-col
        sm="3"
        md="3"
        v-for="item in [{ header: 'Ledger Account' }, { header: 'Amount' }]"
        :key="item.header"
      >
        <h4>{{ item.header }}</h4>
      </v-col>
    </v-row>
    <v-row v-for="item in line_items" :key="item.ledger_account.account_name">
      <v-col sm="3" md="3">
        {{ item.ledger_account.account_name }}
      </v-col>
      <v-col sm="3" md="3">
        {{ formatAsCurrency("R", item.entry_amount) }}
      </v-col>
    </v-row>
    <!--Divider-->
    <v-row class="my-3">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <!--Bank Transaction Details-->
    <v-row>
      <v-col
        sm="3"
        md="3"
        v-for="item in [
          { header: 'Allocated To' },
          { header: 'Bank Account' },
          { header: 'Transaction Type' },
        ]"
        :key="item.header"
      >
        <h4>{{ item.header }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        sm="3"
        md="3"
        v-for="item in [
          {
            name: value.transaction_number,
          },
          { name: value.bank_account_name },
          { name: value.transaction_type },
        ]"
        :key="item.name"
      >
        {{ item.name }}
      </v-col>
    </v-row>
    <!--Divider-->
    <v-row class="my-3">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <!--Remove and Redo-->
    <v-card-actions>
      <v-dialog v-model="remove_and_redo_dialog" max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-spacer></v-spacer>
          <v-btn color="red" outlined v-bind="attrs" v-on="on">
            Remove & Redo
          </v-btn>
        </template>
        <general-journal-entry-remove-dialog
          :remove_and_redo_dialog="this.remove_and_redo_dialog"
          :value="this.value"
          @changeRemoveDialog="changeRemoveDialog($event)"
          @closeDialog="closeDialog($event)"
        >
        </general-journal-entry-remove-dialog>
      </v-dialog>
    </v-card-actions>
  </v-container>
</template>
<script>
import db from "../../../components/firebaseInit";
import { formatAsCurrency } from "../../../composables/external";

export default {
  name: "GeneralJournalEntrySummary",
  props: ["value"],
  components: {
    GeneralJournalEntryRemoveDialog: () =>
      import("./GeneralJournalEntryRemoveDialog"),
  },
  data() {
    return {
      loading: false,
      journal_entry_number: null,
      reference_number: null,
      journal_entry_date: null,
      line_items: [],
      supplier: null,
      journal_entry_created_by_name: null,
      remove_and_redo_dialog: false,
    };
  },
  created() {
    this.getJournalEntry();
  },
  computed: {},
  methods: {
    formatAsCurrency,
    //  Get Journal Entry Fields from database
    getJournalEntry() {
      const entryRef = db
        .collection("general_journal_entries")
        .doc(this.value.journal_entry.journal_entry_id);
      entryRef
        .get()
        .then((doc) => {
          this.journal_entry_number = doc.data().journal_entry_number;
          this.journal_entry_date = doc.data().journal_entry_date;
          this.reference_number = doc.data().reference_number;
          this.line_items = doc.data().line_items;
          this.supplier = doc.data().supplier;
          this.journal_entry_created_by_name =
            doc.data().journal_entry_created_by_name;
        })
        .catch((error) => console.log(error));
    },
    changeRemoveDialog() {
      this.remove_and_redo_dialog = false;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
